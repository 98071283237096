import styled from 'styled-components'

export const HeroSection = styled.section`
	background: ${props=>props.bg};
	position: relative;
	width: 100%;
	margin: auto;
	padding: 150px 15px 70px 15px;	
	@media(min-width: 992px) {
		padding: 150px 30px 70px 30px;
	}
	@media(min-width: 1200px) {
		padding: 250px 30px 70px 30px;
	}
`
HeroSection.defaultProps = {
	bg: "#fff",
}


export const HeroPageTitle = styled.h1`
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width: ${props=>props.maxWidth};
    width:100%;

	font-size: 24px;
	line-height: 32px;
	@media (min-width: 768px) {	
		font-size: 34px;
		line-height: 44px;	
	}
	@media (min-width: 992px) {	
		font-size: 44px;
		line-height: 54px;	
	}
`
HeroPageTitle.defaultProps = {	
	mb: "15px",
	color: "#062c44",
}

export const HeroDesc = styled.div`
	max-width: ${props=>props.maxWidth};
	width:100%;
	margin:0 auto ${props=>props.mb} auto;
	text-align: ${props=>props.textAlign};	
	color: #010920;
	font-family: Roboto;
	font-size: 16px;
	line-height: 26px;
	@media(min-width: 992px){
		font-size: 18px;
    	line-height: 30px;
	}
	p{
		> a{
			color: #ea5f2c;
			font-weight: 600;
			&:hover{
				color:#000;
			}
		}	
	}
`
HeroDesc.defaultProps = {	
	mb: "30px",
}


export const HeroCover = styled.div`
	display:flex;
	flex-wrap:wrap;
	margin:0 -15px;
`
export const LeftHero = styled.div`
	position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width:992px){
		flex:0 0 50%;
		max-width:50%;
	}
`
export const RightHero = styled.div`
	position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width:992px){
		flex:0 0 50%;
		max-width:50%;
	}
`

export const HeroFormWrap = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #98dfff;
  box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
  border-radius: 10px;
  padding:20px 15px;
  @media (min-width: 768px) {
    padding:40px 30px;
  }
  @media (min-width: 992px) {
    padding: 40px;
  }  
`

export const HeroFormTitle = styled.div`
  font-family: Chakra Petch, sans-serif;
  font-weight: 700;
  margin: 0 0 20px;
  text-align:center;
  color: #062c44;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 768px) {    
    font-size: 24px;
    line-height: 32px;
  }
  @media (min-width: 992px) {
    font-size: 34px;
    line-height: 44px;
  }
`

export const HeroFormBody = styled.div`
  margin:0;
  form{
    margin-bottom:0;
  }
`